var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('h3',{staticClass:"ml12",staticStyle:{"margin-top":"20px"}},[_vm._v("Loading")]):_c('h3',{staticClass:"ml12",staticStyle:{"margin-top":"20px"}},[_vm._v("Acegate Enrollees")]),_c('div',{staticClass:"mt20 flex"},[_c('stat',{staticClass:"ml12",attrs:{"title":"Users","stat":_vm.getEnrolleesLength(_vm.enrolleesForState)}}),_c('stat',{staticClass:"ml20",attrs:{"title":"Tests","stat":_vm.totals.tests}})],1),_c('tabs',{staticClass:"mt12 ml8",attrs:{"tabs":[{
		label: 'Enrollees (' + _vm.totals.enrollees + ')',
		value: ''
	}, {
		label: 'Blast from the Past (' + _vm.totals['Blast from the past'] + ' packages)',
		value: 'Blast from the past'
	}, {
		label: 'Test Series - Basic (' + _vm.totals['Test Series - Basic'] + ' packages)',
		value: 'Test Series - Basic'
	}, {
		label: 'Test Series - Plus (' + _vm.totals['Test Series - Plus'] + ' packages)',
		value: 'Test Series - Plus'
	}, {
		label: 'Acegate Advantage (' + _vm.totals['Acegate Advantage'] + ' packages)',
		value: 'Acegate Advantage'
	}]},on:{"tabClicked":function($event){_vm.state = arguments[0].value}}}),_c('table',{staticClass:"ui celled table"},[_vm._m(0),_c('tbody',_vm._l((_vm.enrolleesForState),function(v,k,ix){return _c('tr',{key:k},[_c('td',[_vm._v(_vm._s(ix + 1))]),_c('td',[_c('p',[_vm._v(_vm._s(v.name))]),_c('p',[_vm._v(_vm._s(k))]),_c('p',[_vm._v(_vm._s(v.type))]),_c('p',[_vm._v(_vm._s(v.institute))])]),_c('td',_vm._l((v.packages),function(count,type,ix){return _c('p',{key:type + ix},[_vm._v(_vm._s(type)+" - "+_vm._s(count))])}),0)])}),0)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Sr.No")]),_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Tests")]),_c('th',[_vm._v("Progress")])])])
}]

export { render, staticRenderFns }