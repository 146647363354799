<template>
	<div>
		<div style="wfill flex">
			<button @click="showAddForm = !showAddForm" style="margin-left: auto;">Add University</button>
		</div>
		<table class="ui celled table">
			<thead>
				<tr>
					<th>Name</th>
					<th>City</th>
					<th>State</th>
					<th>Zone</th>
					<th>Categories</th>
					<th>Actions</th>
				</tr>
			</thead>
			<tbody>
				<tr v-if="showAddForm">
					<td>
							<input 
								v-model="form.name" 
								class="fs14 wfill">
						</td>
						<td>
							<input 
								v-model="form.city" 
								class="fs14 wfill">
						</td>
						<td>
							<input 
								v-model="form.state" 
								class="fs14 wfill">
						</td>
						<td>
							<input 
								v-model="form.zone" 
								class="fs14 wfill">
						</td>
						<td>
							<input 
								v-model="form.categories" 
								class="fs14 wfill">
						</td>
						<td>
							<button @click="addUniversity" class="transparent mx4">
								<i style="margin: 0" class="add icon"></i>
							</button>
							<!-- button @click="deleteUniversity(u.id)" class="transparent mx4">
								<i style="margin: 0" class="trash icon"></i>
							</button -->
						</td>
				</tr>
				<tr v-for="u in dbUnis" :key="u.id">
					<td>
						<span v-if="active != u.id">{{u.name}}</span>
						<input 
							@change="updateField(u.id, 'name', $event.target.value)" 
							class="fs14 wfill" v-if="active == u.id" :value="u.name">
					</td>
					<td>
						<span v-if="active != u.id">{{u.city}}</span>
						<input 
							@change="updateField(u.id, 'city', $event.target.value)" 
							class="fs14 wfill" v-if="active == u.id" :value="u.city">
					</td>
					<td>
						<span v-if="active != u.id">{{u.state}}</span>
						<input 
							@change="updateField(u.id, 'state', $event.target.value)" 
							class="fs14 wfill" v-if="active == u.id" :value="u.state">
					</td>
					<td>
						<span v-if="active != u.id">{{u.zone}}</span>
						<input 
							@change="updateField(u.id, 'zone', $event.target.value)" 
							class="fs14 wfill" v-if="active == u.id" :value="u.zone">
					</td>
					<td>
						<span v-if="active != u.id">{{u.categories}}</span>
						<input 
							@change="updateField(u.id, 'categories', $event.target.value)" 
							class="fs14 wfill" v-if="active == u.id" :value="u.categories">
					</td>
					<td>
						<button @click="setActive(u.id)" class="transparent mx4">
							<i style="margin: 0" class="edit icon"></i>
						</button>
						<!-- button @click="deleteUniversity(u.id)" class="transparent mx4">
							<i style="margin: 0" class="trash icon"></i>
						</button -->
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
export default {
	name: 'universities',
	data () {
		return {
			active: '',
			dbUnis: [],
			showAddForm: false,
			form: {
				name: '',
				city: '',
				state: '',
				zone: '',
				categories: ''
			}
		}
	},
	mounted () {
		this.$req.get('universities?_limit=-1').then(r => {
      this.dbUnis = r.data
    }).catch(e => console.log(e))
	},
	methods: {
		setActive (id) {
			if (this.active == id) {
				this.active = ''
				return
			}
			this.active = id
		},
		updateField: function (id, field, value) {
			let obj = {}
			obj[field] = value

			this.$req.put(`universities/${id}`, obj, this.$bus.headers)
				.then(r => {
					let updated = r.data
					let ix = this.dbUnis.findIndex(u => u.id == updated.id)
					this.dbUnis.splice(ix, 1, updated)
				})
				.catch(e => console.log(e))
		},
		addUniversity: function () {
			let form = this.form
			if (Object.values(form).includes('')) {
				this.$bus.notify('Please fill all fields', 'error')
				return
			}
			console.log(form)
			this.$req.post('universities', form, this.$bus.headers)
				.then(r => {
					this.dbUnis.unshift(r.data)
					for (let k of Object.keys(form)) {
						form[k] = ''
					}
				}).catch(e => console.log(e))
		},
		deleteUniversity: function (id) {
			let ix = this.dbUnis.findIndex(u => u.id == id)
			let u = this.dbUnis[ix]
			let x = confirm(`Are you sure you want to delete ${u.name}, ${u.cty}`)
			if (x) {
				this.$req.delete(`universities/${id}`, this.$bus.headers)
				.then(() => {
					this.dbUnis.splice(ix, 1)
					this.$bus.notify(`${u.name} deleted successfully`)
				}).catch(e => console.log(e))
			}
		}
	}
}
</script>