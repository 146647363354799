<template>
  <div class="p40">
    <h3 class="mb12">Bulk Result Upload</h3>
    <select v-model="event">
      <option v-for="e in events" :key="e.id" :value="e.id">
        {{ e.Title }}
      </option>
    </select>
    <button class="mt8 ml12 black" @click="upload">Upload CSV</button>
    <button @click="bulkDeleteEntries" class="black ml12">
      Delete all entries
    </button>
    <br />
    <div class="flex mt12">
      <button
        class="ml0 brown500"
        @click="bulkUploadUsers"
        v-if="users.length > 0"
      >
        Complete Upload
      </button>
      <div
        v-if="users.length > 0"
        class="relative ml12"
        style="
          overflow: hidden;
          width: 200px;
          height: 40px;
          border-radius: 4px;
          background: #f5f5f5;
        "
      >
        <div
          class="hfill"
          style="background: #d8ae79"
          :style="{ width: (progress * 100) / (total || 1) + '%' }"
        ></div>
        <p
          style="
            position: absolute;
            left: 150px;
            right: 0px;
            height: 20px;
            top: calc(50% - 10px);
          "
        >
          {{ Math.round((progress * 100) / (total || 1)) }}%
        </p>
      </div>
    </div>
    <table v-if="users.length > 0" class="ui celled table">
      <thead>
        <tr>
          <th>Team</th>
          <th>Name</th>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="u in users" :key="u.id">
          <td>{{ u["teamId"] }}</td>
          <td>{{ u["Name"] }}</td>
          <td>{{ u["E-Mail"] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "bulkresultupload",
  data() {
    return {
      users: [],
      events: [],
      event: "",
      progress: 0,
      total: 0,
      uploadInProgress: false,
    };
  },
  mounted() {
    this.$req
      .get(`events?_limit=-1`, {
        headers: {
          Authorization: `Bearer ${this.$bus.token}`,
        },
      })
      .then((r) => {
        this.events = r.data;
      })
      .catch((e) => console.log(e));
  },
  methods: {
    bulkUploadUsers: async function () {
      if (!this.event) {
        this.$bus.notify("Please choose an event", "error");
        return;
      }
      let teams = this.users
        .map((u) => u.teamId)
        .filter((v, i, s) => s.indexOf(v) == i);
      this.total = teams.length;
      this.progress = 0;
      this.uploadInProgress = true;
      for (let t of teams) {
        let usrs = this.users.filter((u) => u.teamId == t);
        let resp = await this.$req
          .post(
            "bulkUpload",
            {
              users: usrs,
              eventId: this.event,
            },
            this.$bus.headers
          )
          .catch((e) => console.log(e));
        if (!resp) {
          this.$bus.notify("There was an upload error: Team " + t, "error");
          alert("There was an upload error: Team " + t);
          this.uploadInProgress = false;
          return;
        }
        this.progress += 1;
      }
      // this.$req.post('bulkUpload', {
      //   users: this.users,
      //   eventId: this.event
      // }, this.$bus.headers)
      //   .then(r => console.log(r))
      //   .catch(e => console.log(e))
    },
    bulkDeleteEntries: function () {
      if (confirm("Are you sure") == true) {
        this.$req
          .post(
            `bulkDeleteEntries`,
            {
              id: this.event,
            },
            this.$bus.headers
          )
          .then((r) => {
            this.$bus.notify(r.data.length + " entries deleted");
          })
          .catch((e) => console.log(e));
      } else {
        this.$bus.notify("Whew! That was close.");
      }
    },
    csvJSON: function (str, headerList, quotechar = '"', delimiter = ",") {
      const cutlast = (_, i, a) => i < a.length - 1;
      // const regex = /(?:[\t ]?)+("+)?(.*?)\1(?:[\t ]?)+(?:,|$)/gm; // no variable chars
      const regex = new RegExp(
        `(?:[\\t ]?)+(${quotechar}+)?(.*?)\\1(?:[\\t ]?)+(?:${delimiter}|$)`,
        "gm"
      );
      const lines = str.split("\n");
      const headers =
        headerList ||
        lines
          .splice(0, 1)[0]
          .match(regex)
          .filter(cutlast)
          .map((s) => s.slice(0, s.length - 1));
      const list = [];

      for (const line of lines) {
        const val = {};
        for (const [i, m] of [...line.matchAll(regex)]
          .filter(cutlast)
          .entries()) {
          // Attempt to convert to Number if possible, also use null if blank
          val[headers[i]] = m[2].length > 0 ? Number(m[2]) || m[2] : null;
        }
        list.push(val);
      }
      return list;
    },
    upload: function () {
      let ctx = this;
      var el = document.createElement("input");
      let reader = new FileReader();
      el.setAttribute("type", "file");
      el.addEventListener("change", function () {
        reader.readAsText(this.files[0]);
        reader.onload = () => {
          let csv = reader.result;
          let json = ctx.csvJSON(csv);
          let keys = Object.keys(json[0]);
          json = json.filter((j) => Object.keys(j).length > 0);
          var max = Math.max(
            ...keys.map((k) => parseInt(k.split(":")[1] || 0))
          );
          keys = [
            "Name",
            "E-Mail",
            "Phone",
            "Study Year",
            "College",
            "Passed Year",
            "Organisation Details",
          ];
          for (let entry of json) {
            let teamId = entry["Team No"];
            // eslint-disable-next-line no-unused-vars
            for (var [i, n] of new Array(max).fill(0).entries()) {
              let name = entry["Name : " + (i + 1)];
              if (name && name != " ") {
                let user = {};
                for (let k of keys) {
                  user[k] = entry[k + " : " + (i + 1)];
                }
                ctx.users.push({
                  ...user,
                  teamId,
                });
              }
            }
          }
        };
      });
      el.click();
    },
  },
};
</script>
