<template>
	<div>
		<h3 v-if="loading" style="margin-top: 20px" class="ml12">Loading</h3>
		<h3 v-else style="margin-top: 20px" class="ml12">Acegate Enrollees</h3>
		<div class="mt20 flex">
			<stat class="ml12" title="Users" :stat="getEnrolleesLength(enrolleesForState)"/>
			<stat class="ml20" title="Tests" :stat="totals.tests"/>
			<!-- stat 
				@click.native="state = 'Blast from the past'" 
				class="ml20" title="Blast From The Past" :stat="totals['Blast from the past']"/>
			<stat class="ml20" title="Test Series - Basic" :stat="totals['Test Series - Basic']"/>
			<stat class="ml20" title="Test Series - Plus" :stat="totals['Test Series - Plus']"/>
			<stat class="ml20" title="Acegate Advantage" :stat="totals['Acegate Advantage']" /-->
		</div>
		<tabs class="mt12 ml8" :tabs="[{
			label: 'Enrollees (' + totals.enrollees + ')',
			value: ''
		}, {
			label: 'Blast from the Past (' + totals['Blast from the past'] + ' packages)',
			value: 'Blast from the past'
		}, {
			label: 'Test Series - Basic (' + totals['Test Series - Basic'] + ' packages)',
			value: 'Test Series - Basic'
		}, {
			label: 'Test Series - Plus (' + totals['Test Series - Plus'] + ' packages)',
			value: 'Test Series - Plus'
		}, {
			label: 'Acegate Advantage (' + totals['Acegate Advantage'] + ' packages)',
			value: 'Acegate Advantage'
		}]" @tabClicked="state = arguments[0].value"></tabs>
		<table class="ui celled table">
			<thead>
				<tr>
					<th>Sr.No</th>
					<th>Name</th>
					<th>Tests</th>
					<th>Progress</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(v, k, ix) in enrolleesForState" :key="k">
					<td>{{ix + 1}}</td>
					<td>
						<p>{{v.name}}</p>
						<p>{{k}}</p>
						<p>{{v.type}}</p>
						<p>{{v.institute}}</p>
					</td>
					<td>
						<p v-for="(count, type, ix) in v.packages" :key="type + ix">{{ type }} - {{ count }}</p>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
import stat from './stat'
import tabs from './tabs'
export default {
	name: 'acegate-enrollees',
	components: { stat, tabs },
	data () {
		return {
			packages: [],
			loading: true,
			state: ''
		}
	},
	mounted () {
		this.$req.get('acegate-packages?_limit=-1', this.$bus.headers)
			.then(r => {
				this.packages = r.data
				this.loading = false
			})
			.catch(e => console.log(e))
	},
	computed: {
		byPurchase: function () {
			let packages = this.packages.reduce((s, p) => {
				s[p.paymentId] = (s[p.paymentId] || {
					id: p.user?.id,
					name: p.user?.name,
					type: p.user?.type,
					field: p.user?.field,
					email: p.user?.email,
					institute: p.user?.institute,
					packages: [] 
				})
				s[p.paymentId]['packages'].push({
					type: p.type,
					number: p.tests
				})
				return s
			}, {})

			Object.entries(packages).map(ent => {
				packages[ent[0]]['plan'] = this.deduceByPackage(ent[1]['packages'])
			})
			return packages
		},
		enrolleesForState: function () {
			if (this.state == '')
				return this.enrollees
			else
				return this.enrolleesState
		},
		enrolleesState: function () {
			let obj = {}
			let permissible = []
			if (this.state != '') {
				permissible = this.usersByPurchase(this.state)
			}

			let packages = this.packages?.reduce((s, p) => {
				if (permissible.length > 0 && !permissible.includes(p?.user?.email)) {
					return s
				}
				s[p?.user?.email] = (s[p?.user?.email] || { 
					id: p.user?.id,
					name: p.user?.name,
					type: p.user?.type,
					field: p.user?.field,
					institute: p.user?.institute,
					packages: [] 
				})
				s[p.user?.email]['packages'].push({
					type: p.type,
					paymentId: p.paymentId,
					number: p.tests,
					key: Math.random() + p.paymentId
				})
				return s
			}, {})
			for (let p of Object.entries(packages)) {
				// console.log('Pack', p)
				// let packs = {}
				// for (let pack in p.packages) {
				// 	pack[p.type] = pack[p.type] || 0
				// 	pack[p.type] += pack.number
				// }
				p[1]['packages'] = p[1]['packages'].reduce((s, pack) => {
					s[pack.type] = s[pack.type] || 0
					s[pack.type] += pack.number
					return s
				}, { })
				obj[p[0]] = p[1]
			}
			return obj
		},
		enrollees: function () {
			let obj = {}

			let packages = this.packages?.reduce((s, p) => {
				s[p?.user?.email] = (s[p?.user?.email] || { 
					id: p.user?.id,
					name: p.user?.name,
					type: p.user?.type,
					field: p.user?.field,
					institute: p.user?.institute,
					packages: [] 
				})
				s[p.user?.email]['packages'].push({
					type: p.type,
					paymentId: p.paymentId,
					number: p.tests,
					key: Math.random() + p.paymentId
				})
				return s
			}, {})
			for (let p of Object.entries(packages)) {
				// console.log('Pack', p)
				// let packs = {}
				// for (let pack in p.packages) {
				// 	pack[p.type] = pack[p.type] || 0
				// 	pack[p.type] += pack.number
				// }
				p[1]['packages'] = p[1]['packages'].reduce((s, pack) => {
					s[pack.type] = s[pack.type] || 0
					s[pack.type] += pack.number
					return s
				}, { })
				obj[p[0]] = p[1]
			}
			return obj
		},
		totals: function () {
			let count = Object.keys(this.enrollees).length
			let tests = Object.values(this.enrollees)
				.map(v => Object.values(v.packages).reduce((s, v) => s + v, 0))
				.reduce((s, v) => s + v, 0)
			let packageCounts = Object.values(this.byPurchase)?.reduce((s, v) => {
				s[v?.plan] = (s[v?.plan] || 0)
				s[v?.plan] += 1
				return s
			}, {})
			return {
				enrollees: count,
				tests,
				...packageCounts
			}
		}
	},
	methods: {
		deduceByPackage: function (packages) {
			if (packages?.map(p => p.type.toLowerCase().includes('past paper')).includes(true)) {
				return 'Blast from the past'
			}
			if (packages?.map(p => p.type.toLowerCase().includes('acegate - full') && p.number == 10)?.includes(true)) {
				return 'Acegate Advantage'
			}
			if (packages?.map(p => p.type.toLowerCase().includes('full') && p.number == 5).includes(true)) {
				return 'Test Series - Plus'
			}
			if (packages?.map(p => p.type.toLowerCase().includes('full') && p.number == 3).includes(true)) {
				return 'Test Series - Basic'
			}
			return ''
		},
		getEnrolleesLength: function (enrollees) {
			return Object.keys(enrollees)?.length
		},
		usersByPurchase: function (plan) {
			return Object.values(this.byPurchase).map(p => {
				return {
					id: p.id,
					name: p.name,
					type: p.type,
					field: p.field,
					institute: p.institute,
					email: p.email,
					plan: p.plan
				}
			}).filter(v => v.plan == plan)
				.map(u => u.email)
		}
	}
}
</script>