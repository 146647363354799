<template>
	<div>
		<h3>Unlisted universities</h3>
		<pagination style="display: none" />
		Total: {{notListedUsers.length}}
		<table class="ui celled table">
			<thead>
				<tr>
					<th>Id</th>
					<th>Email</th>
					<th>Institute</th>
					<th>Actions</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="u in notListedUsers" :key="u.id">
					<td>{{u.id}}</td>
					<td>{{u.email}}</td>
					<td>{{u.institute}} - {{u.notListedInstitute}}</td>
					<td>
						<button class="brown500">Update</button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import pagination from '../components/pagination'
export default {
	name: 'unlistedUniversities',
	components: {pagination},
	data () {
		return {
			dbUnis: [],
			notListedUsers: [],
			value: '',
			page: 0
		}
	},
	mounted () {
		this.$req.get('universities?_limit=-1').then(r => {
      this.dbUnis = r.data.sort((u1, u2) => u2.name - u1.name)
    }).catch(e => console.log(e))

		this.$req.get('users?_limit=-1&institute=Not Listed', this.$bus.headers)
			.then(r => {
				this.notListedUsers = r.data
					.filter(u => u.notListedInstitute)
					.sort((un1, un2) => un1.notListedInstitute - un2.notListedInstitute)
			}).catch(e => console.log(e))
	}
}
</script>