<template>
  <div class="stat">
    <h5 style="font-weight: 700;" class="num left black">{{stat}}</h5>
    <p class="title sss left" style="margin: 0px;">{{title}}</p>
  </div>
</template>

<script>
export default {
  name: 'stat',
  props: {
    title: {default: 'statistic'},
    stat: {default: '0'}
  }
}
</script>

<style lang="css" scoped>
  .stat .title {
    margin: 0px;
  }
  .stat .num {
    letter-spacing: 0;
    font-size: 32px;
  }
  @media (max-width: 800px) {
    .stat .num {
      font-size: 20px;
    }
  }
</style>