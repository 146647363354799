<template>
  <div class="mt40" style="overflow-x: scroll">
    <p
      v-if="!hideBackArrow"
      class="nnn pointer pl20 mt40"
      @click="$router.push('/ideace')"
    >
      <i class="angle left icon"></i> Back To Quizzes
    </p>
    <page-header
      :header="quiz.title + ' Rankings'"
      :tabs="[
        { label: 'Rankings', value: 'rankings' },
        { label: 'Non-Participants', value: 'non' }
      ]"
      @tabClicked="changeState(arguments[0].value)"
    />
    <!-- <div class="wfill vertical spaced flex">
      <h2 class="ml20 mt12">{{quiz.title}} Rankings</h2>
      <tabs class="ml20 mt12"></tabs>
    </div> -->
    <div class="wfill flex">
      <button @click="exportCsv" class="mlauto black mr16 mt16">
        Download CSV
      </button>
    </div>
    <table
      v-if="state === 'rankings'"
      class="ui celled table"
      style="width: calc(100% - 40px); margin: 20px;"
    >
      <thead>
        <tr>
          <th>Rank</th>
          <th>Entry ID</th>
          <th>Participant Name</th>
          <th>Email</th>
          <th>Contact</th>
          <th>Chapter</th>
          <th>Type</th>
          <th>Institute</th>
          <th>Zone</th>
          <th>Taken On</th>
          <th>Marks</th>
          <th>Time Taken</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(ent, i) of rankings" :key="i">
          <td>{{ i + 1 }}</td>
          <td>{{ ent.id }}</td>
          <td>
            <p v-for="u in ent.users" :key="u.id">
              {{ u.name }}
            </p>
          </td>
          <td>
            <p v-for="u in ent.users" :key="u.id">
              {{ u.email }}
            </p>
            <span class="aaa">({{ ent.email }})</span>
          </td>
          <td>
            <p v-for="u in ent.users" :key="u.id">
              {{ u.contact }}
            </p>
          </td>
          <td>
            <p v-for="u in ent.users" :key="u.id">
              {{ u.IIIDChapter }}
            </p>
          </td>
          <td>
            <p v-for="u in ent.users" :key="u.id">
              {{ u.type }}
            </p>
          </td>
          <td>
            <p v-for="u in ent.users" :key="u.id">
              {{ u.institute }}
            </p>
          </td>
          <td>
            <p>
              {{ getZone((ent.users[0] || {}).institute) }}
            </p>
          </td>
          <td>{{ getTakenOn(ent) }}</td>
          <td :key="key">{{ getGrade(ent) }}</td>
          <td>{{ getTimeTaken(ent) }}</td>
          <td>
            <button class="brown500" @click="openModal(ent.id)">Grade</button>
          </td>
        </tr>
      </tbody>
    </table>
    <table
      v-if="state === 'non'"
      class="ui celled table"
      style="width: calc(100% - 40px); margin: 20px;"
    >
      <thead>
        <tr>
          <th>Participant Name</th>
          <th>Email</th>
          <th>Contact</th>
          <th>Chapter</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(u, i) of nonParticipants" :key="i">
          <td>{{ u.name }}</td>
          <td>{{ u.email }}</td>
          <td>{{ u.contact }}</td>
          <td>{{ u.IIIDChapter }}</td>
        </tr>
      </tbody>
    </table>
    <div
      class="grading ui modal"
      v-for="ent of rankings"
      :key="ent.id + `quiz-${quiz.id}`"
      :id="ent.id"
    >
      <div class="header">
        Grading entry By {{ ent.users.map(u => u.name).join(", ") }}
      </div>
      <h6 class="mt40 pl20">Open Ended Questions</h6>
      <table
        class="ui celled table"
        style="width: calc(100% - 40px); margin: 20px;"
      >
        <thead>
          <tr>
            <th>No.</th>
            <th>Question</th>
            <th>Answer</th>
            <th>Grade</th>
            <th>Marks Out Of</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="q in ent.entry.questions.filter((q, i) => {
              q.index = i;
              return q.type == 'openEnded';
            })"
            :key="q.id"
          >
            <td>{{ q.index + 1 }}</td>
            <td v-html="q.Question"></td>
            <td>{{ ent.entry.answers[q.index] }}</td>
            <td>
              <input
                type="number"
                min="0"
                :max="q.Marks"
                @change="updateGrade($event, ent, q.index, q.Marks)"
                :value="ent.grades ? ent.grades[q.index] : [0]"
              />
            </td>
            <td>
              {{ q.Marks }}
            </td>
          </tr>
        </tbody>
      </table>
      <h6 class="mt40 pl20">Other Questions</h6>
      <table
        class="ui celled table"
        style="width: calc(100% - 40px); margin: 20px;"
      >
        <thead>
          <tr>
            <th>Question</th>
            <th>Answer</th>
            <th>Correct Answer</th>
            <th>Marks</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="q in ent.entry.questions.filter((q, i) => {
              q.index = i;
              return q.type != 'openEnded';
            })"
            :key="q.id"
          >
            <td>{{ q.Question }}</td>
            <td>
              {{ ent.entry.answers[q.index] }}
            </td>
            <td>
              {{ q.CorrectAnswer }} :
              {{
                (
                  q.Answers[alphabets.indexOf(q.CorrectAnswer)] || {
                    Answer: ""
                  }
                ).Answer
              }}
            </td>
            <td>{{ q.Marks }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import pageHeader from "../components/page-header";
import moment from "moment";
export default {
  name: "quizRanking",
  components: { pageHeader },
  props: {
    _id: { default: "" },
    hideBackArrow: { default: false }
  },
  data() {
    return {
      alphabets: ["A", "B", "C", "D", "E"],
      quiz: [],
      entries: [],
      sortBy: "",
      state: "rankings",
      users: [],
      rankings: [],
      universities: [],
      submissions: [],
      key: this.$bus.random()
    };
  },
  watch: {
    _id: function() {
      this.getEntries();
      this.getQuiz();
      // this.getUsers()
      this.getRanks();
    }
  },
  mounted() {
    this.$req
      .get("universities?_limit=-1")
      .then(r => {
        this.universities = r.data;
      })
      .catch(e => console.log(e));

    this.getEntries();
    this.getQuiz();
    // this.getUsers()
    this.getRanks();
  },
  computed: {
    nonParticipants: function() {
      let participants = this.entries.map(ent => {
        return ent.users;
      });
      let emails = [].concat(...participants).map(p => p.email);
      let subs = this.submissions
        .filter(s => {
          let ems = s.users.map(u => u.email);
          for (let em of ems) {
            if (!emails.includes(em)) {
              return true;
            }
          }
          return false;
        })
        .map(s => s.users);
      return [].concat.apply([], subs);
    }
  },
  methods: {
    openModal: function(id) {
      /* eslint-disable-next-line no-undef */
      $("#" + id).modal("show");
    },
    changeState: function(state) {
      this.state = state;
    },
    getZone: function(institute) {
      let uni = this.universities.find(u => {
        return u.name + ", " + u.city == institute;
      });
      return uni ? uni.zone : "";
    },
    extractContent: function(s) {
      const span = document.createElement("span");
      span.innerHTML = s;
      return span.textContent || span.innerText;
    },
    exportCsv: function() {
      let fields = ["name", "email", "contact", "type", "institute"];
      let json = this.rankings.map(r => {
        let obj = {};
        obj["id"] = r.id;
        obj["registration date"] = r.created_at;
        obj["score"] = r.grade;
        for (let [i, u] of r.users.entries()) {
          for (let f of fields) {
            obj[f + ":" + i] = '"' + u[f] + '"';
            // console.log(i)
            // obj[f] = u[f]
            if (f == "institute") {
              obj["zone" + ":" + i] = '"' + this.getZone(u[f]) + '"';
            }
          }
        }
        for (let [j, question] of r.entry.questions.entries()) {
          obj["Q" + (j + 1)] = this.extractContent(question.Question);
          obj["A" + (j + 1)] = r.entry.answers[j];
          obj["Correct A" + (j + 1)] = question.CorrectAnswer;
        }

        return obj;
      });
      // json2excel({
      //   json,
      //   name: 'user-info-data',
      //   formateDate: 'yyyy/mm/dd'
      // });
      this.downloadCsv(json);
      return json;
    },
    downloadCsv: function(json) {
      var csv = [];
      var maxlen = Math.max(...json.map(j => Object.keys(j).length));
      var maxjson = json.filter(j => Object.keys(j).length == maxlen);
      if (json.length) {
        var keys = Object.keys(maxjson[0]);
        csv.push(keys.join(","));
        json.forEach(item => {
          let vals = keys.map(key => item[key] || "");
          csv.push(vals.join(","));
        });
      }

      csv = csv.join("\n");
      var hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      hiddenElement.target = "_blank";
      hiddenElement.download = this.quiz.title + ".csv";
      hiddenElement.click();
    },
    updateGrade: function(ev, ent, ix, max) {
      let value = ev.target.value;
      if (value == "") return;
      if (parseInt(value) > max) {
        value = max;
      } else if (parseInt(value) < 0) {
        value = 0;
      }

      let grades = ent.grades || {};
      grades[ix] = value;
      this.$req
        .put(
          `quiz-entries/${ent.id}`,
          { grades },
          {
            headers: {
              Authorization: `Bearer ${this.$bus.token}`
            }
          }
        )
        .then(r => {
          this.entries = r.data;
          this.key = this.$bus.random();
        })
        .catch(e => console.log(e));
    },
    getRanks: async function() {
      let ps = this.entries.map(async (ent, i) => {
        ent.grade = this.getGrade(ent);
        this.entries[i] = ent;
      });
      await Promise.all(ps).catch(e => console.log(e));
      let entries = this.entries.sort((e1, e2) => {
        if (e1.grade == e2.grade) {
          return e2.timeLeft - e1.timeLeft;
        }
        return e2.grade - e1.grade;
      });
      this.rankings = entries;
    },
    getQuiz: function() {
      let params = this.$route.params["id"];
      if (this._id) {
        params = this._id;
      }
      this.$req
        .get(`quizzes/${params}`, {
          headers: {
            Authorization: `Bearer ${this.$bus.token}`
          }
        })
        .then(r => {
          this.quiz = r.data;
          this.getUsers();
        })
        .catch(e => console.log(e));
    },
    getEntries: function() {
      let id = this.$route.params["id"];
      if (this._id) {
        id = this._id;
      }

      this.$req
        .get(`getQuizSubmissions?id=${id}`, {
          headers: {
            Authorization: `Bearer ${this.$bus.token}`
          }
        })
        .then(r => {
          let submissions = r.data.submissions;
          this.submissions = submissions;
          this.entries = r.data.entries;
          if (submissions && submissions.length > 0) {
            this.entries = this.entries
              .map(ent => {
                let email = ent.users[0].email;
                let submission = this.submissions.find(s =>
                  s.users.map(u => u.email).includes(email)
                );
                return {
                  ...ent,
                  email,
                  users: (submission || { users: [] }).users
                };
              })
              .filter(ent => ent != null);
            // this.entries = submissions.map(s => {
            //   let entry = this.entries.find(ent => {
            //     let email = ent.users[0].email
            //     return s.users.map(u => u.email).includes(email)
            //   })
            //   if (!entry) {
            //     return null
            //   }
            //   return {
            //     ...entry,
            //     users: s.users
            //   }
            // }).filter(ent => ent != null)
          }
          this.getRanks();
        })
        .catch(e => console.log(e));
    },
    getUsers: function() {
      this.users = [];
      // if (event) {
      //   this.$req.get(`event-sub`)
      // }
      // let params = this.$route.params['id']
      // this.$req.get(`users?_limit=-1`, {
      //   headers: {
      //     Authorization: `Bearer ${this.$bus.token}`
      //   }
      // })
      //   .then(r => {
      //     this.users = r.data
      //   })
      //   .catch(e => console.log(e))
    },
    getGrade(ent) {
      let qs = ent.entry.questions;
      let ans = ent.entry.answers;
      let marks = 0;
      // console.log(ent.users.map(u => u.email))
      ans.map((answ, i) => {
        // console.log(qs[i].CorrectAnswer.toLowerCase().replace(/\s/g, ''), answ.toLowerCase())
        if (qs[i].type == "openEnded") {
          marks += parseInt((ent.grades && ent.grades[i]) || 0);
        }
        if (!answ) {
          return;
        }
        if (
          qs[i].CorrectAnswer.toLowerCase().replace(/\s/g, "") ==
          answ.toLowerCase()
        ) {
          marks += qs[i].Marks;
        }
      });
      return marks;
    },
    getTakenOn(ent) {
      let c = moment(ent.created_at);
      return c.format("hh:mm A Do MMM 'YY");
    },
    getTimeTaken(ent) {
      // let c = moment(ent.created_at)
      // let u = moment(ent.updated_at)
      // let timeTaken = u.diff(c, 'seconds')
      let timeLeft = ent.timeLeft;
      let totalTime = this.quiz.timeLimit * 60;
      // console.log(this.quiz, ent.timeLeft)
      return new Date((totalTime - timeLeft) * 1000)
        .toISOString()
        .substr(11, 8);
    }
  }
};
</script>

<style lang="scss">
.grading.modal {
  height: 500px;
}
</style>
